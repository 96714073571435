import React from 'react'
import * as Cookies from 'js-cookie'

import { useSiteConfig } from '@thg-commerce/enterprise-core'
import { EmailReEngagementModal } from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  EmailEngagementModal,
  EmailEngagementModalProps,
  FormStatus,
} from '@thg-commerce/enterprise-widget-email-engagement-modal'
import { PictureProps } from '@thg-commerce/gravity-system'

export type EmailEngagementModalRendererProps = Omit<
  EmailReEngagementModal,
  'query'
> & {
  picture: PictureProps
}

export const EmailEngagementModalRenderer = (
  props: EmailEngagementModalRendererProps,
) => {
  const { delayEmailReengagementModal } = useSiteConfig()
  const [formStatus, setFormStatus] = React.useState<FormStatus>(
    FormStatus.UNSUBMITTED,
  )
  const [openModal, setOpenModal] = React.useState(false)
  const delay = delayEmailReengagementModal ?? 0

  React.useEffect(() => {
    const hasEngagementCookie =
      Cookies.get('emailEngagementCookie') !== undefined

    if (!hasEngagementCookie) {
      if (delay && delay > 0) {
        const timer = setTimeout(() => {
          setOpenModal(true)
        }, delay)
        return () => clearTimeout(timer)
      }
      setOpenModal(true)
    }
    return
  }, [delay])

  React.useEffect(() => {
    // TODO: cookies should use cookie groups from cookie helper CookieGroup
    switch (formStatus) {
      case FormStatus.SUCCESSFUL:
        Cookies.set('emailEngagementCookie', 'newsletter-accepted', {
          expires: 365,
        })
        break
      case FormStatus.REJECTED:
        Cookies.set('emailEngagementCookie', 'newsletter-rejected', {
          expires: 365,
        })
        break
      case FormStatus.FAILURE:
        Cookies.set('emailEngagementCookie', 'newsletter-failure', {
          expires: 365,
        })
        break
    }
  }, [formStatus])

  const EmailEngagementModalProps: EmailEngagementModalProps = {
    picture: props.picture,
    modalTitle: props.emailReEngagementTitleText || '',
    bulletText: [
      props.emailReEngagementBulletText1 || '',
      props.emailReEngagementBulletText2 || '',
      props.emailReEngagementBulletText3 || '',
    ],
    status: formStatus,
    open: openModal,
    statusHandler: (status) => setFormStatus(status),
    closeHandler: () => setOpenModal(false),
  }

  if (typeof window === 'undefined') {
    return null
  }

  return <EmailEngagementModal {...EmailEngagementModalProps} />
}
